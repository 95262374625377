@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Contrail One';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border: 1px solid black;
}

body {
  background-color: black;
  letter-spacing: 1px;
}

#error {
  color: #2c63fc;
  font-family: 'Contrail One';
}

.rightArrowIntensity:hover {
  scale: 110%;

}

.leftArrowIntensity:hover {
  scale: 110%;
}

/* Landing page styles */


/* Large Screen */
@media (min-width: 601px) {
  .landingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landingPageLogo {
    margin-top: 30px;
  }

  .landingTransparentOverlay {
    background-color: rgb(0 0 0 / 0.7);
    opacity: 0.7;
    position: 'absolute';
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 2;
  }

  .landingLogoContainer {
    background-color: black;
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .landingButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:eco%2Cw_1200/MTg5NzYxMTgxNjQzMTg3OTAw/the-philosophy-behind-bikram-yoga.jpg');
    background-size: cover;
    height: 100vh;
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
  }

  .landingButton {
    font-family: 'Contrail One';
    font-size: 20px;
    padding: 15px;
    margin-left: 5rem;
    margin-right: 5rem;
    border: 2px solid #2c63fc;
    border-radius: 3rem;
    background-color: black;
    color: #A7FF37;
    z-index: 2;
  }

  .landingButton:hover {
    background-color: #2c63fc;
    color: #A7FF37;
  }
}

/* Small Screen */
@media (max-width: 600px) {
  .landingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
  }

  .landingLogoContainer {
    background-color: black;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landingPageLogo {
    max-height: 300px;
    max-width: 190px;
  }

  .landingButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-image: url('https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:eco%2Cw_1200/MTg5NzYxMTgxNjQzMTg3OTAw/the-philosophy-behind-bikram-yoga.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    right: 0;
    top: 0;
  }

  .landingButton {
    font-family: 'Contrail One';
    margin: 5px;
    font-size: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    border: 2px solid #2c63fc;
    border-radius: 4rem;
    background-color: black;
    color: #A7FF37;
    z-index: 2;
  }

  .landingButton:hover {
    background-color: #2c63fc;
    color: #A7FF37;
  }
}

/* Login in page styles */

@media (min-width: 600px){
.loginContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("https://post.healthline.com/wp-content/uploads/2020/08/man-deadlift-1200x628-facebook-1200x628.jpg");
  background-size: cover;
}

.transparentOverlay{
  background-color: rgb(0 0 0 / 0.7);
  opacity: 0.7;
  position: 'absolute';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
}

.loginForm {
  border: 3px solid #A7FF37;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3rem;
  background-color: rgba(0, 0, 0, 0.718);
  position: absolute;
  z-index: 2;
  box-shadow: 0 0 12px rgb(191, 191, 191);
}

.signinForm {
  height: 90vh;
}

.loginForm label {
  color: #A7FF37;
  font-family: 'Contrail One';
  font-size: 20px;
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
}

.loginForm input {
  width: 80%;
  font-family: 'Contrail One';
  border-radius: 3rem;
  font-size: 25px;
  padding: 5px;
  margin-bottom: 15px;
}

.loginForm div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.loginForm button {
  width: 74%;
  font-family: 'Contrail One';
  background-color: black;
  color: #A7FF37;
  border: 3px solid #A7FF37;
  border-radius: 3rem;
  font-size: 25px;
  margin-bottom: 15px;
}

.loginForm p{
  font-family: 'Contrail One';
  color: #A7FF37;
}

.loginForm button:hover {
  background-color: #A7FF37;
  color: black;
}

.loginFormLabel {
  margin-left: 0rem;
}

.loginForm div {
  display: flex;
  justify-content: center;
}
}

@media (max-width:600px){
  .loginContainer {
      height: 120vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-image: url("https://post.healthline.com/wp-content/uploads/2020/08/man-deadlift-1200x628-facebook-1200x628.jpg");
      background-size: cover;
    }
  
    .transparentOverlay {
      background-color: rgb(0 0 0 / 0.7);
      opacity: 0.7;
      position: 'absolute';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
    }
  
    .loginForm {
      border: 3px solid #A7FF37;
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 3rem;
      background-color: rgba(0, 0, 0, 0.718);
      position: absolute;
      z-index: 2;
      box-shadow: 0 0 12px rgb(191, 191, 191);
    }
  
    .signinForm {
      margin-top: 80px;
      height: 100vh;
    }
  
    .loginForm label {
      color: #A7FF37;
      font-family: 'Contrail One';
      font-size: 20px;
      display: flex;
      margin-top: 15px;
      margin-bottom: 10px;
    }
  
    .loginForm input {
      width: 80%;
      font-family: 'Contrail One';
      border-radius: 3rem;
      font-size: 25px;
      padding: 5px;
      margin-bottom: 15px;
    }
  
    .loginForm div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 90%;
    }
  
    .loginForm button {
      width: 74%;
      font-family: 'Contrail One';
      background-color: black;
      color: #A7FF37;
      border: 3px solid #A7FF37;
      border-radius: 3rem;
      font-size: 25px;
      margin-bottom: 15px;
    }
  
    .loginForm p {
      font-family: 'Contrail One';
      color: #A7FF37;
    }
  
    .loginForm button:hover {
      background-color: #A7FF37;
      color: black;
    }
  
    .loginFormLabel {
      margin-left: 0rem;
    }
  
    .loginForm div {
      display: flex;
      justify-content: center;
    }
}

/* Generate Workout Styles */
@media (min-width: 600px){
.generatedResponse {
  font-family: 'Contrail One';
  color:#A7FF37;
}

.generatedExercisesWarning{
  font-family: 'Contrail One';
  color: #2c63fc;
}

.xButton {
  margin: 3px;
  font-family: 'Contrail One';
  background-color: black;
  color: #2c63fc;
  border: 2px solid #2c63fc;
  border-radius: 3rem;
  font-size: 20px;
}

.xButton:hover {
  background-color: #2c63fc;
  color: black;
}

.addMuscleGroupButton {
  margin-left: 40px;
  font-family: 'Contrail One';
  background-color: black;
  color: #37a5ff;
  border: 2px solid #37a5ff;
  border-radius: 3rem;
  font-size: 20px;
}

.addMuscleGroupButton:hover {
  background-color: #37a5ff;
  border: 2px solid #37a5ff;
  color: black;
}

}

@media (max-width: 600px){
  .generatedResponse {
      margin-top: 30px;
      font-family: 'Contrail One';
      color: #A7FF37;
    }
  
    .generatedExercisesWarning {
      font-family: 'Contrail One';
      color: #2c63fc;
    }
  
    .xButton {
      margin: 3px;
      font-family: 'Contrail One';
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 20px;
    }
  
    .xButton:hover {
      background-color: #2c63fc;
      color: black;
    }
  
    .addMuscleGroupButton {
      margin-left: 40px;
      font-family: 'Contrail One';
      background-color: black;
      color: #37a5ff;
      border: 2px solid #37a5ff;
      border-radius: 3rem;
      font-size: 20px;
    }
  
    .addMuscleGroupButton:hover {
      background-color: #37a5ff;
      border: 2px solid #37a5ff;
      color: black;
    }
  
    .broomIcon:hover {
      color: black;
    }
}


/*Pick Exercise Page */
@media (min-width: 600px){
  
  .pickExerciseContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("https://www.windsorgym.com.au/wp-content/uploads/2021/01/Black-White-1920x1140.jpg");
    background-size: cover;
  }

  .pickExerciseDivsContainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 20;
  }

  .cornerLogo {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 20;
  }

  .goBack {
    position: absolute;
    top: 35px;
    left: 35px;
    z-index: 20;
    border: 2px solid #A7FF37;
    padding: 5px;
    border-radius: 3rem;
  }

  .goBack:hover {
    background-color: #2c63fc;
    scale: 110%;
    transition-duration: 150ms;
  }
    .pickTransparentOverlay {
      background-color: rgb(0 0 0 / 0.7);
      opacity: 0.7;
      position: 'absolute';
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      right: 0;
      height: 100vh;
      width: 100%;
    }
  
    .pickExerciseBox {
      width: 30%;
      height: 70%;
      border-radius: 3rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      border: solid 3px #A7FF37;
      box-shadow: 0 0 12px rgb(191, 191, 191);
      background-color: rgba(0, 0, 0, 0.818);
    }
  
    .pickExerciseTitle {
      text-align: center;
      font-size: 25px;
    }
  
    .selectedExercises {
      width: 25%;
      height: 70%;
      border: solid 3px #A7FF37;
      border-radius: 3rem;
      padding: 2rem;
      overflow: auto;
      box-shadow: 0 0 12px rgb(191, 191, 191);
      background-color: rgba(0, 0, 0, 0.818);
    }
  
    .muscleGroupSelector {
      padding-left: 10px;
      padding-right: 10px;
      margin: 10px;
      border-radius: 3rem;
      height: 36px;
      background-color: black;
      border: solid #A7FF37;
      text-align: center;
    }
  
    .muscleGroupButton {
      font-family: 'Contrail One';
      height: 36px;
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 10px;
    }
  
    .muscleGroupButton:hover {
      background-color: #2c63fc;
      border: 2px solid #2c63fc;
      color: black;
    }
  
    .muscleGroupForm {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .singleExercise {
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  
    .generatedExercises {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
    }
  
    .singleGeneratedExercise {
      margin: 10px;
      border: solid #2c63fc;
      border-radius: 3rem;
      width: 90%;
      background-color: #000000;
      box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
    }
  
    .helperMessage {
      font-family: Contrail One;
      font-size: small;
      color: #A7FF37;
      text-align: center;
      background-color: #2c64fc77;
      border-radius: 20px;
      padding: 5px;
    }
  
    .helperDiv {
      display: flex;
      width: 50%;
      margin: 0 auto;
      padding: 5px;
    }
}

@media (max-width: 600px) {

  .pickExerciseContainer {
    width: 100vw;
    height: 190vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("https://www.windsorgym.com.au/wp-content/uploads/2021/01/Black-White-1920x1140.jpg");
    background-size: cover;
  }

  .pickExerciseDivsContainer {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 180vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 20;
  }

  .cornerLogo {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 20;
  }

  .goBack {
    position: absolute;
    top: 35px;
    left: 35px;
    z-index: 20;
    border: 2px solid #A7FF37;
    padding: 5px;
    border-radius: 3rem;
  }

  .goBack:hover {
    background-color: #2c63fc;
    scale: 110%;
    transition-duration: 150ms;
  }

  .pickTransparentOverlay {
    background-color: rgb(0 0 0 / 0.7);
    opacity: 0.7;
    position: 'absolute';
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    right: 0;
    height: 190vh;
    width: 100%;
  }

  .pickExerciseBox {
    width: 80%;
    height: 40%;
    margin-top: 180px;
    margin-bottom: 20px;
    border-radius: 3rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    border: solid 3px #A7FF37;
    box-shadow: 0 0 12px rgb(191, 191, 191);
    background-color: rgba(0, 0, 0, 0.818);
  }

  .pickExerciseTitle {
    text-align: center;
    font-size: 25px;
  }

  .selectedExercises {
    width: 80%;
    height: 40%;
    border: solid 3px #A7FF37;
    border-radius: 3rem;
    padding: 2rem;
    overflow: auto;
    box-shadow: 0 0 12px rgb(191, 191, 191);
    background-color: rgba(0, 0, 0, 0.818);
  }

  .muscleGroupSelector {
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    border-radius: 3rem;
    height: 36px;
    background-color: black;
    border: solid #A7FF37;
    text-align: center;
  }

  .muscleGroupButton {
    font-family: 'Contrail One';
    height: 36px;
    background-color: black;
    color: #2c63fc;
    border: 2px solid #2c63fc;
    border-radius: 3rem;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
  }

  .muscleGroupButton:hover {
    background-color: #2c63fc;
    border: 2px solid #2c63fc;
    color: black;
  }

  .muscleGroupForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .singleExercise {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .generatedExercises {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }

  .singleGeneratedExercise {
    margin: 10px;
    border: solid #2c63fc;
    border-radius: 3rem;
    width: 90%;
    background-color: #000000;
    box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
  }

  .helperMessage {
    font-family: Contrail One;
    font-size: small;
    color: #A7FF37;
    text-align: center;
    background-color: #2c64fc77;
    border-radius: 20px;
    padding: 5px;
  }

  .helperDiv {
    display: flex;
    width: 50%;
    margin: 0 auto;
    padding: 5px;
  }

  .rightArrowIntensity {
    position: absolute;
    z-index: 2;
    right: 5rem;
    background-color: #a8ff377a;
    border-radius: 100%;
    margin-top: 20px;
  }

  .leftArrowIntensity {
    position: absolute;
    z-index: 2;
    left: 5rem;
    background-color: #A7FF37;
    border-radius: 100%;
        margin-top: 20px;
  }

  .arrowContainer {
    padding-bottom: 10px;
    margin-top: 30px;
    width: 15rem;
    height: 5rem;
    z-index: 3;
  }
}



/* Intensity and skill level page */
@media (min-width: 600px) {
  .intensityTransparentOverlay {
      background-color: rgb(0 0 0 / 0.7);
      opacity: 0.7;
      position: 'absolute';
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      right: 0;
      height: 100vh;
      width: 100%;
    }
    .intensityContainer {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-image: url("https://images.squarespace-cdn.com/content/v1/5e81f296eca8656d4cc7f9ac/1625218190083-45ABEW5ZJG5BIZRCS5O6/SwiftGym036_sr.jpg?format=2500w");
      background-size: cover;
      position: absolute;
    }

    .intensityDiv {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      width: 30%;
      height: 70%;
      background-color: rgba(0, 0, 0, 0.818);
      border: 3px solid #A7FF37;
      border-radius: 32px;
      box-shadow: 0 0 12px rgb(191, 191, 191);
      z-index: 2;
    }

    .intensityDivsContainer {
      position: absolute;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .intesityTitle {
      color: white;
      display: flex;
      width: 100vw;
      justify-content: center;
      position: absolute;
      z-index: 20;
      font-size: 52px;
      top: 0;
      margin-top: 10px;
      font-family: 'Contrail One';
      color: #A7FF37;
    }

    .title {
      text-align: center;
      font-family: 'Contrail One';
      font-size: 30px;
      color: #A7FF37;
    }

    .intensityButton {
      width: 70%;
      height: 20%;
      margin-bottom: 10px;
      border: 2px solid #2c63fc;
      border-radius: 20px;
      font-family: 'Contrail One';
      font-size: 30px;
      background-color: #000000;
      color: #A7FF37;
    }

    .intensityButtonSelected {
      width: 70%;
      height: 20%;
      margin-bottom: 10px;
      border: 3px solid #2c63fc;
      border-radius: 20px;
      font-family: 'Contrail One';
      font-size: 30px;
      color: #2c63fc;
      background-color: #000000;
      box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
    }  

    .intensityButton:hover {
      background-color: #2c63fc;
      color: black;
    }

    .rightArrowIntensity {
      position: absolute;
      z-index: 2;
      right: 30px;
      background-color: #a8ff377a;
      border-radius: 100%;
    }
    
    .leftArrowIntensity {
      position: absolute;
      z-index: 2;
      left: 30px;
      background-color: #A7FF37;
      border-radius: 100%;
    }

    .account {
      display: flex;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      z-index: 20;
    }
    .arrowBottomMargin{
      margin-bottom: none;
    }
}

@media (max-width: 600px) {
  .intensityTransparentOverlay {
      background-color: rgb(0 0 0 / 0.7);
      opacity: 0.7;
      position: 'absolute';
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      right: 0;
      height: 140vh;
      width: 100%;
    }
  
    .intensityContainer {
      width: 100vw;
      height: 140vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-image: url("https://images.squarespace-cdn.com/content/v1/5e81f296eca8656d4cc7f9ac/1625218190083-45ABEW5ZJG5BIZRCS5O6/SwiftGym036_sr.jpg?format=2500w");
      background-size: cover;
      position: absolute;
    }
  
    .intensityDiv {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 1rem;
      width: 80%;
      height: 70%;
      background-color: rgba(0, 0, 0, 0.818);
      border: 3px solid #A7FF37;
      border-radius: 32px;
      margin-bottom: 20px;
      box-shadow: 0 0 12px rgb(191, 191, 191);
      z-index: 2;
    }
  
    .intensityDivsContainer {
      position: absolute;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      top: 180px;
      justify-content: space-around;
      align-items: center;
    }
  
    .intesityTitle {
      color: white;
      display: flex;
      width: 100vw;
      justify-content: center;
      position: absolute;
      z-index: 20;
      font-size: 52px;
      top: 80px;
      margin-top: 10px;
      font-family: 'Contrail One';
      color: #A7FF37;
    }
  
    .title {
      text-align: center;
      font-family: 'Contrail One';
      font-size: 30px;
      color: #A7FF37;
    }
  
    .intensityButton {
      width: 70%;
      height: 20%;
      margin-bottom: 10px;
      border: 2px solid #2c63fc;
      border-radius: 20px;
      font-family: 'Contrail One';
      font-size: 25px;
      background-color: #000000;
      color: #A7FF37;
    }
  
    .intensityButtonSelected {
      width: 70%;
      height: 20%;
      margin-bottom: 10px;
      border: 3px solid #2c63fc;
      border-radius: 20px;
      font-family: 'Contrail One';
      font-size: 30px;
      color: #2c63fc;
      background-color: #000000;
      box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
    }
  
    .intensityButton:hover {
      background-color: #2c63fc;
      color: black;
    }

    .rightArrowIntensity {
        position: absolute;
        z-index: 2;
        right: 5rem;
        background-color: #a8ff377a;
        border-radius: 100%;
    }
    
    .leftArrowIntensity {
        position: absolute;
        z-index: 2;
        left: 5rem;
        background-color: #A7FF37;
        border-radius: 100%;
    }

    .equipmentRightArrowIntensity {
      z-index: 20;
      right: 5rem;
      background-color: #a8ff377a;
      border-radius: 100%;
    }

    .equipmentLeftArrowIntensity {
      z-index: 20;
      left: 5rem;
      background-color: #A7FF37;
      border-radius: 100%;
    }

    .arrowBottomMargin {
      margin-bottom: 20px;
    }

    .arrowContainer {
      padding-bottom: 10px;
      width: 15rem;
      height: 5rem;
      z-index: 3;
    }

    .account{
      display: flex;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      z-index: 20;
    }
}


/* Equipment Selection Page */

@media (min-width: 600px) {
  .equipmentContainer {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-image: url("https://i.pinimg.com/originals/a3/1d/e3/a31de3187c7fa939a863f4b8593ef1de.jpg");
      background-size: cover;
      position: absolute;
    }

    .equipmentSelectorAndButton {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.25rem;
    }
  
    .equipmentTransparentOverlay {
      background-color: rgba(18, 18, 18, 0.7);
      opacity: 0.7;
      position: 'absolute';
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      right: 0;
      height: 100vh;
      width: 100%;
    }
  
    .equipmentDivsContainer {
      position: absolute;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  
    .equipmentDiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 5%;
      width: 50%;
      background-color: black;
      border: 3px solid #A7FF37;
      border-radius: 32px;
      z-index: 2;
      overflow: scroll;
      box-shadow: 0 0 12px rgb(191, 191, 191);
      background-color: rgba(0, 0, 0, 0.818);
    }

   .equipmentItemDiv{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    margin: 5px;
   }

    .equipmentItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      color: #A7FF37;
      font-family: 'Contrail One';
      margin: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 2px solid #2c63fc;
      border-radius: 32px;
      text-align: center;
      box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
      background-color: #000000;
    }
  
    .equipmentItemSelected {
      display: flex;
      justify-content: space-between;
      width: 70%;
      align-items: center;
      color: #A7FF37;
      font-family: 'Contrail One';
      margin: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 2px solid #A7FF37;
      border-radius: 32px;
      text-align: center;
      box-shadow: 0 0 2px #fff, 0 0 5px #A7FF37, 0 0 7px #A7FF37, 0 0 10px #A7FF37;
      background-color: #000000;
    }
  
    .equipCreatable {
      padding-left: 10px;
      padding-right: 10px;
      height: 36px;
      text-align: center;
      background-color: #000000;
      border-color: #A7FF37;
      border-radius: 32px;
      font-family: Contrail One;
      color: #A7FF37
    }
  
    .creatableMuscleGroup {
      padding-left: 10px;
      padding-right: 10px;
      margin: 10px;
      height: 36px;
      text-align: center;
      border-radius: 32px;
      font-family: Contrail One;
    }
  
  
    .equipmentSkipButton {
      font-family: 'Contrail One';
      height: 36px;
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  
    .equipmentSkipButton:hover {
      background-color: #2c63fc;
      color: black;
      scale: 110%;
      transition-duration: 400ms;
    }
  
    .equipmentModalContinueButton {
      font-family: 'Contrail One';
      height: 36px;
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 15px;
  
    }
  
    .equipmentModalContinueButton:hover {
      background-color: #2c63fc;
      color: black;
      scale: 110%;
      transition-duration: 400ms;
    }
  
    .equipmentModalBackButton {
      font-family: 'Contrail One';
      height: 36px;
      background-color: black;
      color: #A7FF37;
      border: 2px solid #A7FF37;
      border-radius: 3rem;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 15px;
  
    }
  
    .equipmentModalBackButton:hover {
      background-color: #A7FF37;
      color: black;
      scale: 110%;
      transition-duration: 400ms;
    }
  
    .equipmentAddButton {
      font-family: 'Contrail One';
      background-color: black;
      color: #A7FF37;
      border: 2px solid #A7FF37;
      border-radius: 3rem;
      font-size: 15px;
      padding-left: 5px;
      padding-right: 5px;
      margin-left: 10px;
    }
  
    .equipmentAddButton:hover {
      background-color: #A7FF37;
      border: 2px solid #A7FF37;
      color: black;
    }
  
    .equipmentSkipButtonContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  
    .createGymButton {
      font-family: 'Contrail One';
      height: 30px;
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 10px;
    }
  
    .createGymButton:hover {
      background-color: #2c63fc;
      border: 2px solid #2c63fc;
      color: black;
    }
  
    .gymSelect {
      margin-left: 15px;
      border-radius: 20px;
      padding: 5px;
      font-family: Contrail One;
      color: rgba(0, 0, 0, 0.818)
    }
  
    #gymError {
      color: #2c63fc;
      font-family: 'Contrail One';
      font-size: small;
      margin-left: 10px;
    }

    .equipmentRightArrowIntensity {
      position: absolute;
      z-index: 20;
      right: 5rem;
      background-color: #a8ff377a;
      border-radius: 100%;
    }

    .equipmentLeftArrowIntensity {
      position: absolute;
      z-index: 20;
      left: 5rem;
      background-color: #A7FF37;
      border-radius: 100%;
    }
}

@media (max-width: 600px){
  .equipmentContainer {
      width: 100vw;
      height: 120vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-image: url("https://i.pinimg.com/originals/a3/1d/e3/a31de3187c7fa939a863f4b8593ef1de.jpg");
      background-size: cover;
      position: absolute;
    }

    .equipmentSelectorAndButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.25rem;
      height: 80px;
    }
  
    .equipmentTransparentOverlay {
      background-color: rgba(18, 18, 18, 0.7);
      opacity: 0.7;
      position: 'absolute';
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      right: 0;
      height: 120vh;
      width: 100%;
    }
  
    .equipmentDivsContainer {
      position: absolute;
      width: 100vw;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-top: 160px;
    }
  
    .equipmentDiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      top: 180px;
      padding: 5%;
      width: 80%;
      height: 70vh;
      background-color: black;
      border: 3px solid #A7FF37;
      border-radius: 32px;
      z-index: 2;
      overflow: scroll;
      box-shadow: 0 0 12px rgb(191, 191, 191);
      background-color: rgba(0, 0, 0, 0.818);
    }

    .equipmentItemDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px;
    }
  
    .equipmentItem {
      display: flex;
      justify-content: space-between;
      width: 70%;
      align-items: center;
      color: #A7FF37;
      font-family: 'Contrail One';
      margin: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 2px solid #2c63fc;
      border-radius: 32px;
      text-align: center;
      box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
      background-color: #000000;
    }
  
    .equipmentItemSelected {
      display: flex;
      justify-content: space-between;
      width: 70%;
      align-items: center;
      color: #A7FF37;
      font-family: 'Contrail One';
      margin: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 2px solid #A7FF37;
      border-radius: 32px;
      text-align: center;
      box-shadow: 0 0 2px #fff, 0 0 5px #A7FF37, 0 0 7px #A7FF37, 0 0 10px #A7FF37;
      background-color: #000000;
    }
  
    .equipCreatable {
      padding-left: 10px;
      padding-right: 10px;
      height: 36px;
      text-align: center;
      background-color: #000000;
      border-color: #A7FF37;
      border-radius: 32px;
      font-family: Contrail One;
      color: #A7FF37
    }
  
    .creatableMuscleGroup {
      padding-left: 10px;
      padding-right: 10px;
      margin: 10px;
      height: 36px;
      text-align: center;
      border-radius: 32px;
      font-family: Contrail One;
    }
  
    .equipmentSkipButton {
      font-family: 'Contrail One';
      height: 36px;
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  
    .equipmentSkipButton:hover {
      background-color: #2c63fc;
      color: black;
      scale: 110%;
      transition-duration: 400ms;
    }
  
    .equipmentModalContinueButton {
      font-family: 'Contrail One';
      height: 36px;
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 15px;
  
    }
  
    .equipmentModalContinueButton:hover {
      background-color: #2c63fc;
      color: black;
      scale: 110%;
      transition-duration: 400ms;
    }
  
    .equipmentModalBackButton {
      font-family: 'Contrail One';
      height: 36px;
      background-color: black;
      color: #A7FF37;
      border: 2px solid #A7FF37;
      border-radius: 3rem;
      font-size: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 15px;
  
    }
  
    .equipmentModalBackButton:hover {
      background-color: #A7FF37;
      color: black;
      scale: 110%;
      transition-duration: 400ms;
    }
  
    .equipmentAddButton {
      font-family: 'Contrail One';
      background-color: black;
      color: #A7FF37;
      border: 2px solid #A7FF37;
      border-radius: 3rem;
      font-size: 15px;
      padding-left: 5px;
      padding-right: 5px;
      margin-left: 10px;
    }
  
    .equipmentAddButton:hover {
      background-color: #A7FF37;
      border: 2px solid #A7FF37;
      color: black;
    }
  
    .equipmentSkipButtonContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  
    .createGymButton {
      font-family: 'Contrail One';
      height: fit-content;
      background-color: black;
      color: #2c63fc;
      border: 2px solid #2c63fc;
      border-radius: 3rem;
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 10px;
    }
  
    .createGymButton:hover {
      background-color: #2c63fc;
      border: 2px solid #2c63fc;
      color: black;
    }
  
    .gymSelect {
      margin-left: 15px;
      border-radius: 20px;
      padding: 5px;
      font-family: Contrail One;
      color: rgba(0, 0, 0, 0.818)
    }
  
    #gymError {
      color: #2c63fc;
      font-family: 'Contrail One';
      font-size: small;
      margin-left: 10px;
    }
}

/* Equipment Selection Page */

/* Finalize Page */

@media (min-width: 600px){

.finalizeContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-size: cover;
  position: absolute;
  background-image: url("https://images.squarespace-cdn.com/content/v1/5d07f87f8ac0bf00019389a4/1638220970423-8M3TQBPVVO1PJ559SEFP/TN_Website_Bench.jpg?format=2500w");
    }

.finalizeTransparentOverlay {
  background-color: rgba(18, 18, 18, 0.7);
  opacity: 0.7;
  position: 'absolute';
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  right: 0;
  height: 100vh;
  width: 100%;
}
    
.finalizeDuration {
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px;
  border-radius: 3rem;
  height: 36px;
  background-color: rgb(37, 37, 37);
  border: solid #A7FF37;
  text-align: center;
  color: #A7FF37;
  width: 40%;
}

.finalizeResponseContainer{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.finalResponseDiv{
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 15px;
  width: 50%;
  height: 75%;
  color: #A7FF37;
  background-color: rgba(0, 0, 0, 0.73);
  border: 3px solid #A7FF37;
  border-radius: 32px;
  z-index: 2;
  overflow: scroll;
  box-shadow: 0 0 12px rgb(191, 191, 191);
}

.finalResponseParts {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A7FF37;
}

.finalizeButton {
  font-family: 'Contrail One';
  margin-top: 20px;
  width: 90%;
  background-color: black;
  color: #2c63fc;
  border: 2px solid #2c63fc;
  border-radius: 3rem;
  font-size: 50px;
  padding: 10px;
}

.finalizeButton:hover {
  background-color: #2c63fc;
  color: black;
  scale: 110%;
  transition-duration: 400ms;
}

.savedMessage{
    font-family: 'Contrail One';
    color: #2c63fc;
    font-size: 20px;
    margin: 15px;
}

.beatLoader{
margin: 15px;
}

 .finalizeLeftArrowIntensity {
   position: absolute;
   z-index: 2;
   left: 30px;
   background-color: #A7FF37;
   border-radius: 100%;
 }

 .finalizeEquipmentItem {
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: #A7FF37;
   font-family: 'Contrail One';
   margin: 10px;
   padding-left: 10px;
   padding-right: 10px;
   padding-top: 5px;
   padding-bottom: 5px;
   border: 2px solid #2c63fc;
   border-radius: 32px;
   text-align: center;
   box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
   background-color: #000000;
 }

 .finalizeEquipmentDiv {
   display: grid;
   grid-template-columns: repeat(3, minmax(0, 1fr));
   align-items: center;
   margin: 1.25rem;
 }

  .finalizeDurationContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 600px){
.finalizeContainer {
    width: 100vw;
    height: 130vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-size: cover;
    position: absolute;
    background-image: url("https://images.squarespace-cdn.com/content/v1/5d07f87f8ac0bf00019389a4/1638220970423-8M3TQBPVVO1PJ559SEFP/TN_Website_Bench.jpg?format=2500w");
  }

  .finalizeTransparentOverlay {
    background-color: rgba(18, 18, 18, 0.7);
    opacity: 0.7;
    position: 'absolute';
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    right: 0;
    height: 130vh;
    width: 100%;
  }

  .finalizeDuration {
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    border-radius: 3rem;
    height: 36px;
    background-color: rgb(37, 37, 37);
    border: solid #A7FF37;
    text-align: center;
    color: #A7FF37;
    width: 40%;
  }

  .finalizeResponseContainer {
    position: absolute;
    width: 100vw;
    height: 130vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .finalResponseDiv {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    padding: 15px;
    width: 80%;
    height: 65%;
    color: #A7FF37;
    background-color: rgba(0, 0, 0, 0.73);
    border: 3px solid #A7FF37;
    border-radius: 32px;
    z-index: 2;
    overflow: scroll;
    box-shadow: 0 0 12px rgb(191, 191, 191);
  }

  .finalResponseParts {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A7FF37;
  }

  .finalizeButton {
    font-family: 'Contrail One';
    margin-top: 20px;
    width: 90%;
    background-color: black;
    color: #2c63fc;
    border: 2px solid #2c63fc;
    border-radius: 3rem;
    font-size: 30px;
    padding: 10px;
  }

  .finalizeButton:hover {
    background-color: #2c63fc;
    color: black;
    scale: 110%;
    transition-duration: 400ms;
  }

  .savedMessage {
    font-family: 'Contrail One';
    color: #2c63fc;
    font-size: 20px;
    margin: 15px;
  }

  .beatLoader {
    margin: 15px;
  }

  .finalizeLeftArrowIntensity {
    position: absolute;
    bottom: 20px;
    z-index: 2;
    left: 5rem;
    background-color: #A7FF37;
    border-radius: 100%;
  }
  .finalizeEquipmentDiv{
    display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      align-items: center;
      margin: 1.25rem;
  }

    .finalizeEquipmentItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #A7FF37;
      font-family: 'Contrail One';
      margin: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 2px solid #2c63fc;
      border-radius: 32px;
      text-align: center;
      box-shadow: 0 0 2px #fff, 0 0 5px #2c63fc, 0 0 7px #2c63fc, 0 0 10px #2c63fc;
      background-color: #000000;
    }

    .finalizeDurationContainer {
      display: flex;
      align-items: center;
      text-align: center;
    }
}
/* Finalize Page */

/* Home Page */

.suggestedWorkoutDiv {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 10px;
  margin-right: 20px;
  width: 50%;
  color: #A7FF37;
  background-color: rgba(0, 0, 0, 0.818);
  border: 3px solid #A7FF37;
  border-radius: 32px;
  z-index: 2;
  overflow: scroll;
  box-shadow: 0 0 12px rgb(191, 191, 191);
}

.regenerateWorkout {
  font-family: 'Contrail One';
  background-color: black;
  color: #2c63fc;
  border: 2px solid #2c63fc;
  border-radius: 3rem;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.regenerateWorkout:hover {
  background-color: #2c63fc;
  color: black;
}


/* Home Page */

.dashboardTitle {
  font-size: 3rem;
  line-height: 1;
  padding: 1rem; 
  display: flex; 
  justify-content: center; 
  align-items: center;
}
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
  
      50% {
        background-position: 100% 50%;
      }
  
      100% {
        background-position: 0% 50%;
      }
    }


/* Large Screen */
@media (min-width: 601px) {
  .dashboardContainer {
      padding: 30px;
      background: radial-gradient(circle at center, #000000, #2c63fc);
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
    }

  .calChartContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .dashboardTitle {
    font-size: 3rem;
    line-height: 1;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chartContainerDiv {
    margin: 15px;
    padding: 20px;
    border: 3px solid #A7FF37;
    border-radius: 32px;
    background-color: rgba(0, 0, 0, 0.818);
    box-shadow: 0 0 12px rgb(191, 191, 191);
  }

  .calContainerDiv {
    margin: 15px;
    padding: 20px;
    border: 3px solid #A7FF37;
    border-radius: 32px;
    background-color: rgba(0, 0, 0, 0.818);
    box-shadow: 0 0 12px rgb(191, 191, 191);
  }

  .dashboardWorkouts {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  .suggestedWorkoutDiv {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 10px;
    margin-right: 20px;
    width: 50%;
    color: #A7FF37;
    background-color: rgba(0, 0, 0, 0.818);
    border: 3px solid #A7FF37;
    border-radius: 32px;
    z-index: 2;
    overflow: scroll;
    box-shadow: 0 0 12px rgb(191, 191, 191);
  }

  .suggestedWorkoutTitleButtonDiv {
    display: flex; 
    justify-content: space-between;
  }

  .workoutModalTitle {
    font-size: 3rem;
    line-height: 1;
    text-align: center;
    font-family: Contrail One;
  }
        
}

/* Small Screen */
@media (max-width: 600px) {
  .dashboardContainer {
    padding-top: 6rem;
    padding-right: 1rem;
    padding-left: 1rem;
    background: radial-gradient(circle at center, #000000, #2c63fc);
    animation: gradient 15s ease infinite;
  }

  .dashboardTitle {
    font-size: 2rem;
    line-height: 1;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calChartContainer {
    display: flex;
    flex-direction: column;
  }

  .chartContainerDiv {
    margin: 10px;
    padding: 20px;
    height: 40vh;
    border: 3px solid #A7FF37;
    border-radius: 32px;
    background-color: rgba(0, 0, 0, 0.818);
    box-shadow: 0 0 12px rgb(191, 191, 191);
  }

  .calContainerDiv {
    margin: 10px;
    padding: 20px;
    border: 3px solid #A7FF37;
    border-radius: 32px;
    background-color: rgba(0, 0, 0, 0.818);
    box-shadow: 0 0 12px rgb(191, 191, 191);
  }

  .dashboardWorkouts {
    display: flex; 
    flex-direction: column;
    align-items: center;
    height: 100%; 
    justify-content: space-between;
  }

  .suggestedWorkoutDiv {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 10px;
    width: 95%;
    color: #A7FF37;
    background-color: rgba(0, 0, 0, 0.818);
    border: 3px solid #A7FF37;
    border-radius: 32px;
    z-index: 2;
    overflow: scroll;
    box-shadow: 0 0 12px rgb(191, 191, 191);
  }

  .suggestedWorkoutTitleButtonDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .workoutModalTitle {
    font-size: 2rem;
    line-height: 1;
    text-align: center; 
    font-family: Contrail One;
  }
}

.Mui-selected-custom:focus {
  background-color: #A7FF37;
  color: 'black';
}

@media (min-width: 600px){
  .pulse {
    animation: pulse 1s infinite;
    background-color: #A7FF37;
    box-shadow: 0 0 40px rgb(255, 255, 255);
  }
}

@media (max-width: 600px) {
  .pulse {
    animation: pulse 1s infinite;
    background-color: #A7FF37;
  }
}


@keyframes pulse {

  0%,
  100% {
    transform: translateX(10px)
  }

  50% {
    transform: translateX(20px)
  }
}